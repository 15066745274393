import React from 'react';

import { formFieldFactory } from '@eas/common-web';
import MuiSelect, { SelectProps } from '@material-ui/core/Select';
import { EmptyWrapper } from '../empty-wrapper/empty-wrapper';

type Props = SelectProps & {
  onChange: (value: string | null) => void;
};

export function Select({ onChange, children, ...otherProps }: Props) {
  return (
    <MuiSelect
      onChange={(e) => onChange(e.target.value as string)}
      {...otherProps}
    >
      {children}
    </MuiSelect>
  );
}

export const FormFieldSelect = formFieldFactory(Select, EmptyWrapper);
