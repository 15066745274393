import React from 'react';
import { useHistory } from 'react-router-dom';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { TableColumn, TableAction } from './table-types';
import colors from '../../constants/colors';

interface TableHeadProps<T> {
  tableTitle: string;
  columns: TableColumn<T>[];
  expandableRows: boolean;
  loading: boolean;
  actions?: TableAction[];
}

const useStyles = makeStyles(() => ({
  tableTitle: {
    fontWeight: 700,
    fontSize: 20,
  },
  upperHeadPanel: {
    padding: '1rem 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButton: {
    backgroundColor: colors.blue,
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
  headLabelCell: {
    color: '#9FA2B4',
    fontWeight: 700,
  },
}));

export function EnhancedTableHead<T>({
  columns,
  expandableRows,
  tableTitle,
  actions = [],
  loading,
}: TableHeadProps<T>) {
  const classes = useStyles();
  const { push } = useHistory();

  return (
    <TableHead>
      <TableRow>
        <TableCell colSpan={12}>
          <div className={classes.upperHeadPanel}>
            <div className={classes.tableTitle}>{tableTitle}</div>

            <div>
              {actions.map(({ label, callback, Icon }) => (
                <Button
                  key={label}
                  variant="contained"
                  startIcon={Icon}
                  onClick={() => callback(push)}
                  className={classes.actionButton}
                >
                  {label}
                </Button>
              ))}
            </div>
          </div>
          {loading && <LinearProgress />}
        </TableCell>
      </TableRow>
      <TableRow>
        {columns.map(({ id, label, align }) => (
          <TableCell key={id} className={classes.headLabelCell} align={align}>
            {label}
          </TableCell>
        ))}
        {expandableRows && <TableCell width={50} />}
      </TableRow>
    </TableHead>
  );
}
