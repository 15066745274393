import { Backend } from '../../../api/endpoints';

export const fetchFiles = async (): Promise<Backend.PersonBillsDto> => {
  const res = await fetch('/api/prukazy/auth/bill/', {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (!res.ok) {
    throw new Error(await res.json());
  }

  return await res.json();
};
