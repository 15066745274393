import React from 'react';

import { CardStateCell } from '../my-cards-state-cell';
import { Card } from '../../../../models';
import { DateCell } from '../../../../components/table/table-cells/table-date-cell';
import { DateTimeCell } from '../../../../components/table/table-cells/table-date-time-cell';

type DetailItemsType = {
  label: string;
  render: (data: Card) => JSX.Element | string;
}[];

function sanitizeRenderedData(data: any) {
  if (data == null) {
    return '-';
  } else return data.toString();
}

const typeOfHolderMapping: Record<Card['typeOfHolder'], string> = {
  C: 'Výdejní centrum',
  O: 'Osoba',
  ORG: 'Organizační složka',
  Z: 'Ztraceno',
};

export const DetailItems: DetailItemsType = [
  {
    label: 'Jméno a přijmení',
    render: ({ fullName }) => `${fullName}`,
  },
  {
    label: 'Stav průkazu',
    render: function CardStateItem(card) {
      return <CardStateCell data={card.cardState} allData={card} />;
    },
  },
  {
    label: 'Stav oběhu',
    render: (card) => typeOfHolderMapping[card.typeOfHolder],
  },
  {
    label: 'Platnost karty od',
    render: function CardValidFrom({ cardValidFrom }) {
      return <DateCell data={(cardValidFrom as unknown) as string} />;
    },
  },
  {
    label: 'Poslední změna karty',
    render: function LastChange({ cardLastChange }) {
      return <DateTimeCell data={(cardLastChange as unknown) as string} />;
    },
  },
  {
    label: 'Poslední licence',
    render: function LastLicence({ licenceValidFrom }) {
      return <DateCell data={(licenceValidFrom as unknown) as string} />;
    },
  },
  {
    label: 'Číslo licence',
    render: ({ licenceNumber }) => sanitizeRenderedData(licenceNumber),
  },
  {
    label: 'Poslední změna licence',
    render: function LastLicenceChange({ licenceLastChange }) {
      return <DateTimeCell data={(licenceLastChange as unknown) as string} />;
    },
  },
];
