import React from 'react';

import { UserInfoContext } from './user-info-context';
import { useUserInfo } from './user-info-context-hook';

interface Props {
  children: React.ReactNode;
}

export function UserInfoContextProvider({ children }: Props) {
  const userInfoCtx = useUserInfo();

  return (
    <UserInfoContext.Provider value={userInfoCtx}>
      {children}
    </UserInfoContext.Provider>
  );
}
