import { useMemo, useState, useCallback, useContext } from 'react';

import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { CardContextType, ModifyCardFnc, ReplaceCardFnc } from './types';
import { Card } from '../../models';
import { fetchAllCards } from './card-context-api';

export const useCards = (): CardContextType => {
  const [allCards, setAllCards] = useState<Card[]>([]);
  const [loading, setLoading] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const blockedCards = useMemo(
    () => allCards.filter((card) => card.typeOfHolder === 'Z'),
    [allCards]
  );

  const activeCards = useMemo(
    () => allCards.filter((card) => new Date(card.cardValidTo) >= new Date()),
    [allCards]
  );

  const loadCards = useCallback(async () => {
    try {
      setLoading(true);
      const { count, items } = await fetchAllCards();

      if (items && count !== undefined) {
        setAllCards(items);
      }
    } catch (err) {
      showSnackbar('Načtení průkazů selhalo', SnackbarVariant.ERROR);
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);

  const replaceCard: ReplaceCardFnc = useCallback((id, newCard) => {
    setAllCards((previous) =>
      previous.map((card) => (card.id === id ? newCard : card))
    );
  }, []);

  const modifyCard: ModifyCardFnc = useCallback((id, fields) => {
    setAllCards((previous) =>
      previous.map((card) => (card.id === id ? { ...card, ...fields } : card))
    );
  }, []);

  const cardCtx = useMemo(
    () => ({
      loadCards,
      allCards,
      blockedCards,
      activeCards,
      replaceCard,
      modifyCard,
      loading,
    }),
    [
      loadCards,
      activeCards,
      blockedCards,
      allCards,
      replaceCard,
      modifyCard,
      loading,
    ]
  );

  return cardCtx;
};
