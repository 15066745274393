import React, { useContext, useEffect, useMemo } from 'react';

import { SnackbarContext, SnackbarVariant } from '@eas/common-web';
import { useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { usePaymentStatus } from './payment-status-hook';
import { DialogContentProps } from '../../../../components/dialog/types';
import { DefaultDialog } from '../../../../components/dialog/vpuk-dialog/vpuk-default-dialog';
import { useInterval } from '../../../../hooks/use-interval';
import { resolvePayment } from './payment-status-api';

interface InitialValues {
  paymentLink: string;
  paymentNumber: number;
}

const useStyles = makeStyles(() => ({
  icon: {
    color: 'green',
  },
}));

export function PaymentStatusDialogContent({
  initialValues,
}: DialogContentProps<InitialValues>) {
  const { status, fetchStatus } = usePaymentStatus(
    initialValues!.paymentNumber
  );
  const { showSnackbar } = useContext(SnackbarContext);
  const { push } = useHistory();
  const classes = useStyles();

  const isCaptured = useMemo(() => status === 'CAPTURED', [status]);

  useInterval(fetchStatus, isCaptured ? null : 2000);

  useEffect(() => {
    if (isCaptured) {
      showSnackbar('Platba proběhla úspěšně', SnackbarVariant.SUCCESS);
      try {
        resolvePayment();
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }
  }, [isCaptured, showSnackbar]);

  const handleBeforeClose = () => {
    push('/portal/prukazy');
  };

  return (
    <DefaultDialog
      title="Dokončení platby"
      beforeClose={isCaptured ? handleBeforeClose : undefined}
      actions={
        !isCaptured && (
          <Button
            component="a"
            href={initialValues!.paymentLink}
            target="_blank"
            rel="noreferrer"
          >
            Přesměrovat na platební bránu
          </Button>
        )
      }
    >
      {isCaptured ? (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <CheckCircleOutlineIcon className={classes.icon} />
          </Grid>
          <Grid item>Platba proběhla úspěšně, okno můžete zavřít.</Grid>
        </Grid>
      ) : (
        <>
          <p>
            Než budete pokračovat, dokončete prosím platbu na platební bráně.
          </p>
          <LinearProgress />
        </>
      )}
    </DefaultDialog>
  );
}
