import React from 'react';

import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { SidePanelNav } from './side-panel-nav';
import logoCol from '../../assets/logo-uk-col.png';
import logoRow from '../../assets/logo-uk-row.png';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '1rem',
  },
  header: {
    padding: '1rem 0',
  },
  logo: {
    height: 'auto',
    maxWidth: 200,
    [theme.breakpoints.down('md')]: {
      maxWidth: 125,
    },
  },
}));

export function SidePanel() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <aside className={classes.root}>
      <Grid
        container
        alignItems="center"
        justify="center"
        spacing={1}
        className={classes.header}
      >
        <Grid item>
          <img
            src={isMobile ? logoCol : logoRow}
            alt="Charles University logo"
            className={classes.logo}
          />
        </Grid>
      </Grid>
      <SidePanelNav />
    </aside>
  );
}
