import { Result, Card } from '../../models';

export async function fetchAllCards(): Promise<Result<Card>> {
  const response = await fetch(`/api/prukazy/auth/card/search-all-cards`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
  const json = await response.json();

  return json;
}
