import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function CardIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.5996 5.99988H15.5997V2.39995C15.5997 1.07998 14.5197 0 13.1997 0H10.7998C9.47981 0 8.39984 1.07998 8.39984 2.39995V5.99988H2.39995C1.07998 5.99988 0 7.07985 0 8.39984V21.5996C0 22.9196 1.07998 23.9995 2.39995 23.9995H21.5996C22.9196 23.9995 23.9995 22.9196 23.9995 21.5996V8.39984C23.9995 7.07985 22.9196 5.99988 21.5996 5.99988ZM8.39984 11.9998C9.39581 11.9998 10.1998 12.8037 10.1998 13.7997C10.1998 14.7957 9.39581 15.5997 8.39984 15.5997C7.40385 15.5997 6.59986 14.7957 6.59986 13.7997C6.59986 12.8037 7.40385 11.9998 8.39984 11.9998ZM11.9998 19.1995H4.79989V18.2997C4.79989 17.0997 7.19985 16.4997 8.39984 16.4997C9.59981 16.4997 11.9998 17.0997 11.9998 18.2997V19.1995ZM13.1997 8.39984H10.7998V2.39995H13.1997V8.39984ZM19.1995 17.3997H14.3997V15.5997H19.1995V17.3997ZM19.1995 13.7997H14.3997V11.9998H19.1995V13.7997Z" />
    </SvgIcon>
  );
}
