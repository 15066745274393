import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import colors from '../../constants/colors';
import { HeaderMenu } from './header-menu';
import { UserInfoContext } from '../user-info-context/user-info-context';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1.5rem 0',
  },
  title: {
    fontSize: '1.5rem',
    color: 'black',
    fontWeight: 700,
  },
  label: {
    color: colors.gray,
  },
  userInfoContainer: {
    [theme.breakpoints.down('lg')]: {
      order: 3,
    },
  },
  name: {
    marginRight: theme.spacing(2),
  },
}));

export function Header() {
  const classes = useStyles();
  const { userInfo, loading } = useContext(UserInfoContext);
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const Loader = smallScreen ? LinearProgress : CircularProgress;

  return (
    <header>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Breadcrumbs separator={<NavigateNextIcon />}>
            <Typography component="h1" className={classes.title}>
              Portál průkazů
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item className={classes.userInfoContainer} xs={12} xl="auto">
          {loading ? (
            <Loader />
          ) : (
            <Grid container>
              <Grid item className={classes.name}>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.label}>Jméno:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      {userInfo?.firstName} {userInfo?.lastName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  <Grid item>
                    <Typography className={classes.label}>
                      Dat. narození:
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{userInfo?.birthDate}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <HeaderMenu />
        </Grid>
      </Grid>
    </header>
  );
}
