import React, { useContext } from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import {
  FilesProvider,
  FormAnchorProvider,
  UserContext,
} from '@eas/common-web';
import { makeStyles } from '@material-ui/core/styles';

import { MyCards } from './my-cards/my-cards';
import { MyFiles } from './my-files/my-files';
import { NewCard } from './new-card/new-card';
import { Profile } from './profile/profile';
import { Header } from '../../components/header/header';
import { SidePanel } from '../../components/side-panel/side-panel';
import { DialogProvider } from '../../components/dialog/dialog-context-provider';
import { CardContextProvider } from '../../components/card-context/card-context-provider';
import { UserInfoContextProvider } from '../../components/user-info-context/user-info-context-provider';

const useStyles = makeStyles(() => ({
  sidePanelWrapper: {
    background: '#F9F9F9',
    height: '100vh',
  },
  contentWrapper: {
    padding: '0 16px 16px',
    backgroundColor: '#fff',
    overflow: 'scroll',
    maxHeight: '100vh',
  },
}));

export function CardPortalApp() {
  const path = '/portal';
  const classes = useStyles();
  const { isLogedIn } = useContext(UserContext);

  if (!isLogedIn()) {
    return <Redirect to="/" />;
  }

  return (
    <FilesProvider url="/api/prukazy/files">
      <FormAnchorProvider>
        <UserInfoContextProvider>
          <CardContextProvider>
            <DialogProvider>
              <Grid container>
                <Hidden smDown>
                  <Grid item md={2} className={classes.sidePanelWrapper}>
                    <SidePanel />
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={10} className={classes.contentWrapper}>
                  <Header />
                  <Switch>
                    <Route
                      exact
                      path={`${path}/prukazy/novy`}
                      component={NewCard}
                    />
                    <Route path={`${path}/prukazy`} component={MyCards} />
                    <Route exact path={`${path}/soubory`} component={MyFiles} />
                    <Route exact path={`${path}/profil`} component={Profile} />
                  </Switch>
                </Grid>
              </Grid>
            </DialogProvider>
          </CardContextProvider>
        </UserInfoContextProvider>
      </FormAnchorProvider>
    </FilesProvider>
  );
}
