import { useEffect, useState, useContext } from 'react';

import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { fetchPickupPlaces } from '../new-card-api';
import { Backend } from '../../../../api/endpoints';

export function usePickupPlaces() {
  const [loading, setLoading] = useState(true);
  const [places, setPlaces] = useState<Backend.Workplace[]>([]);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const places = await fetchPickupPlaces();
        setPlaces(places);
      } catch (err) {
        showSnackbar(
          'Načtení míst pro vyzvednutí průkazu selhalo',
          SnackbarVariant.ERROR
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [showSnackbar]);

  return { places, loading };
}
