import React, { FC } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { PageSection } from './page-section';
import { PageHeader } from './page-header';

const useStyles = makeStyles(() => ({
  root: {
    border: '1px solid rgba(223, 224, 235, 1)',
    '& .Mui-error': {
      fontSize: '0.75rem',
    },
    width: '100%',
  },
}));

type PageType = FC & {
  Section: typeof PageSection;
  Header: typeof PageHeader;
};

export const Page: PageType = ({ children }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.root}>
      {children}
    </Paper>
  );
};

Page.Section = PageSection;
Page.Header = PageHeader;
