import { useEffect, useState, useMemo, useContext } from 'react';

import { SnackbarContext, SnackbarVariant } from '@eas/common-web';

import { Backend } from '../../../../api/endpoints';
import { fetchLicenceRights, fetchCardRights } from '../new-card-api';

export function useCardRights() {
  const [loading, setLoading] = useState(true);
  const [licences, setLicences] = useState<Backend.LicenceRight[]>([]);
  const [cards, setCards] = useState<Backend.CardRight[]>([]);
  const { showSnackbar } = useContext(SnackbarContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { items: licenceItems } = await fetchLicenceRights();
        const { items: cardItems } = await fetchCardRights();

        setLicences(licenceItems ?? []);
        setCards(cardItems ?? []);
      } catch (err) {
        showSnackbar(
          'Načtení dostupných licencí selhalo',
          SnackbarVariant.ERROR
        );
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [showSnackbar]);

  const possibleLicences = useMemo(
    () =>
      licences
        .filter(
          ({ possibleToUse, cardAlreadyExists = false, price }) =>
            possibleToUse && !cardAlreadyExists && price !== null
        )
        .map((licence, index) => ({
          ...licence,
          id: `${licence.cardType}-${licence.licenceType}-${index}`,
        })),
    [licences]
  );

  const possibleCards = useMemo(
    () =>
      cards
        .filter(({ possibleToUse }) => possibleToUse)
        .map((card, index) => ({
          ...card,
          id: `${card.cardType}-None-${index}`,
        })),
    [cards]
  );

  const cardsAndLicences = useMemo(
    () => [...possibleLicences, ...possibleCards],
    [possibleCards, possibleLicences]
  );

  return { licences, cardsAndLicences, loading };
}
