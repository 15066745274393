import React from 'react';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';

export function DateCell({ data }: { data: string | null }) {
  if (data == null) {
    return <>-</>;
  }
  const label = new Date(data);
  const dateString = format(label, 'dd.MM.yyyy');

  return (
    <Typography
      title={dateString}
      component="span"
      style={{ fontSize: '0.75rem' }}
    >
      {dateString}
    </Typography>
  );
}
