import React from 'react';

import { Card } from '../../../models';

interface CardStateCellProps {
  data: Card['cardState'];
  allData: Card;
}

const textMapping: Record<Card['cardState'], string> = {
  N: 'nově založený',
  S: 'skartovaný',
  R: 'registrovaný',
  V: 'vytištěný',
};

export const CardStateCell = ({ data }: CardStateCellProps) => (
  <span>{textMapping[data]}</span>
);
