import React from 'react';

import { CardContext } from './card-context';
import { useCards } from './card-context-hook';

interface Props {
  children: React.ReactNode;
}

export function CardContextProvider({ children }: Props) {
  const cardsCtx = useCards();

  return (
    <CardContext.Provider value={cardsCtx}>{children}</CardContext.Provider>
  );
}
