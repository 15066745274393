import { useRef, useContext, useState, useCallback } from 'react';
import {
  FormHandle,
  SnackbarContext,
  SnackbarVariant,
  UserContext,
} from '@eas/common-web';

import { NewCardValues, CardOrLicence, CardOrderDto } from '../new-card-types';
import { Backend } from '../../../../api/endpoints';
import { fetchPaymentLink, uploadPhoto, fetchCardOrder } from '../new-card-api';
import { DialogContext } from '../../../../components/dialog/dialog-context';
import { PaymentStatusDialogContent } from '../payment-status/payment-status';
import { Me } from '../../../../models';
import { useHistory } from 'react-router-dom';

export function useNewCard(cardsAndLicences: CardOrLicence[]) {
  const [submitting, setSubmitting] = useState(false);
  const ref = useRef<FormHandle<NewCardValues>>(null);
  const { open } = useContext(DialogContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const { user } = useContext<UserContext<Me>>(UserContext);
  const history = useHistory();

  const validate = async () => {
    if (!ref.current) {
      return;
    }
    const errors = await ref.current.validateForm();
    ref.current.setErrors(errors);
  };

  const handleLicenceOrder = useCallback(
    async (values: NewCardValues, card: Backend.LicenceRight) => {
      if (!user || !values.photo) {
        showSnackbar('Objednání selhalo', SnackbarVariant.ERROR);
        return;
      }

      try {
        const photo = await uploadPhoto(values.photo);

        const body: Backend.PurchaseCardDto = {
          firstName: card.firstName,
          lastName: card.lastName,
          personId: card.personId,
          gdpr: values.gdpr,
          personNumber: user.id,
          cardType: card.cardType,
          licenceType: card.licenceType,
          cardValidFrom: card.cardRightValidFrom,
          cardValidTo: card.cardRightValidTo,
          licenceValidFrom: card.licenceRightValidFrom,
          licenceValidTo: card.licenceRightValidTo,
          photo,
          pickupWorkplaceId: values.pickupWorkplace,
          email: values.email,
          requirePayment: card.requirePayment,
          possibleToUse: card.possibleToUse,
          orgId: card.orgId,
        };

        const { paymentNumber, link } = await fetchPaymentLink(body);
        window.open(link, '_blank');
        open({
          size: 'md',
          initialValues: {
            paymentLink: link,
            paymentNumber,
          },
          Content: PaymentStatusDialogContent,
          disableBackdropClick: true,
        });
      } catch (err) {
        showSnackbar('Vytvoření platby selhalo', SnackbarVariant.ERROR);
      } finally {
        setSubmitting(false);
      }
    },
    [open, showSnackbar, user]
  );

  const handleCardOrder = useCallback(
    async (values: NewCardValues, card: Backend.CardRight) => {
      if (!user || !values.photo) {
        showSnackbar('Objednání selhalo', SnackbarVariant.ERROR);
        return;
      }

      try {
        const photo = await uploadPhoto(values.photo);

        const body: CardOrderDto = {
          firstName: card.firstName,
          lastName: card.lastName,
          personId: card.personId,
          gdpr: values.gdpr,
          personNumber: user.id,
          cardType: card.cardType,
          cardValidFrom: card.cardRightValidFrom,
          cardValidTo: card.cardRightValidTo,
          photo,
          pickupWorkplaceId: values.pickupWorkplace,
          email: values.email,
          possibleToUse: card.possibleToUse,
          orgId: card.orgId,
        };

        await fetchCardOrder(body);
        showSnackbar('Objednání proběhlo úspěšně', SnackbarVariant.SUCCESS);
        history.push('/portal/prukazy');
      } catch (err) {
        showSnackbar('Objednání selhalo', SnackbarVariant.ERROR);
      } finally {
        setSubmitting(false);
      }
    },
    [history, showSnackbar, user]
  );

  const handleSubmit = useCallback(
    async (values: NewCardValues) => {
      setSubmitting(true);
      await validate();

      if (!ref.current || ref.current.errors.length) {
        setSubmitting(false);
        console.log(ref.current?.errors);
        return;
      }

      const card = cardsAndLicences.find(({ id }) => id === values.cardId);

      if (!card) {
        showSnackbar('Objednání selhalo', SnackbarVariant.ERROR);
        return;
      }

      if ('licenceType' in card) {
        handleLicenceOrder(values, card);
      } else {
        handleCardOrder(values, card);
      }
    },
    [cardsAndLicences, handleCardOrder, handleLicenceOrder, showSnackbar]
  );

  return { ref, submitting, handleSubmit, validate };
}
