import React, { useContext, useState, useEffect, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles } from '@material-ui/core/styles';
import { FormContext, UserContext } from '@eas/common-web';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useFormErrors } from '../../../hooks/use-form-errors';
import colors from '../../../constants/colors';
import avatar from './assets/avatar.svg';
import { NewCardValues, CardOrLicence } from './new-card-types';
import { CardPreview } from '../../../components/card-preview/card-preview';
import { Me } from '../../../models';

const imageSizes = {
  height: 159,
  width: 132,
};

const useStyles = makeStyles(() => ({
  image: {
    border: '1.5px solid rgba(223, 224, 235, 1)',
    padding: 6,
    height: imageSizes.height,
    width: imageSizes.width,
    objectFit: 'cover',
  },
  button: {
    color: 'white',
    backgroundColor: colors.blue,
    '&:hover': {
      backgroundColor: colors.blue,
    },
  },
  list: {
    paddingInlineStart: 0,
    listStylePosition: 'inside',
  },
  photoTitle: {
    fontWeight: 700,
    color: colors.gray,
  },
}));

type Props = {
  validate: () => Promise<void>;
  cardsAndLicences: CardOrLicence[];
  disabled?: boolean;
};

export function NewCardPhoto({ validate, cardsAndLicences, disabled }: Props) {
  const [file, setFile] = useState<null | File>(null);
  const [imageSrc, setImageSrc] = useState<string>(avatar);
  const classes = useStyles();
  const formCtx = useContext<FormContext<NewCardValues>>(FormContext);
  const { getFieldError } = useFormErrors<NewCardValues>();
  const values = formCtx.getFieldValues();
  const { user } = useContext<UserContext<Me>>(UserContext);

  const cardType = useMemo(() => {
    const card = cardsAndLicences.find((card) => card.id === values.cardId);
    if (!card) {
      return;
    }

    return 'licenceType' in card ? card.licenceType : card.cardType;
  }, [cardsAndLicences, values.cardId]);

  useEffect(() => {
    if (!file) {
      setImageSrc(avatar);
      return;
    }

    const url = URL.createObjectURL(file);
    setImageSrc(url);

    validate();

    return () => URL.revokeObjectURL(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.files?.[0] || null;
    formCtx.setFieldValue('photo', value);
    setFile(value);
  };

  return (
    <Grid container spacing={4} justify="space-between" alignItems="center">
      <Grid item>
        <Grid container spacing={4}>
          <Grid item>
            <img
              src={imageSrc}
              alt="nahraná fotografie"
              className={classes.image}
            />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography component="span" className={classes.photoTitle}>
                  Fotografie studenta
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  component="label"
                  disabled={disabled}
                >
                  Nahrát fotografii
                  <input
                    name="photo"
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <FormHelperText error>{getFieldError('photo')}</FormHelperText>
              </Grid>
              <Grid item>
                <ul className={classes.list}>
                  <li>Min. rozměry fotografie - 414 x 532 px</li>
                  <li>Max. rozměry fotografie - 1656 x 2128 px</li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <CardPreview
          imageSrc={imageSrc}
          cardType={cardType}
          name={user?.name ?? 'Neznámý uživatel'}
        />
      </Grid>
    </Grid>
  );
}
