export const fetchBlockCard = async (id: number) =>
  await fetch(`/api/prukazy/auth/card/${id}/block`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });

export const fetchUnblockCard = async (id: number) =>
  await fetch(`/api/prukazy/auth/card/${id}/unblock`, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json' }),
  });
