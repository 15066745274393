import React, { useContext, useCallback, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import { useRouteMatch, Route, Switch } from 'react-router-dom';

import { Table } from '../../../components/table/table';
import {
  TableColumn,
  TableAction,
} from '../../../components/table/table-types';
import { Card } from '../../../models';
import { CardStateCell } from './my-cards-state-cell';
import { CardDetails } from './row-detail/card-details';
import { AllCardsExpandedCardRow } from './my-cards-expanded-row';
import { DateCell } from '../../../components/table/table-cells/table-date-cell';
import { CardContext } from '../../../components/card-context/card-context';

const myCardsColumns: TableColumn<Card>[] = [
  {
    id: 'licenceNumber',
    label: 'Číslo průkazu',
  },
  {
    id: 'licenceValidTo',
    label: 'Platnost',
    CellComponent: DateCell,
  },
  {
    id: 'cardType',
    label: 'Typ',
  },
  {
    id: 'cardState',
    label: 'Stav průkazu',
    CellComponent: CardStateCell,
  },
];

const actions: TableAction[] = [
  {
    label: 'Objednat průkaz',
    callback: (push) => push('/portal/prukazy/novy'),
    Icon: <AddIcon />,
  },
];

export function MyCards() {
  const {
    loadCards,
    allCards,
    blockedCards,
    activeCards,
    loading,
  } = useContext(CardContext);
  const { path } = useRouteMatch();

  useEffect(() => {
    loadCards();
  }, [loadCards]);

  const CardTableFactory = useCallback(
    (title: string, cards: Card[]) => (
      <Table<Card>
        columns={myCardsColumns}
        expandableRows
        title={title}
        actions={actions}
        DetailComponent={CardDetails}
        ExpandedComponent={AllCardsExpandedCardRow}
        rows={cards}
        count={cards.length}
        loading={loading}
      />
    ),
    [loading]
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Switch>
          <Route exact path={path}>
            {CardTableFactory('Všechny průkazy', allCards)}
          </Route>
          <Route exact path={`${path}/aktivni`}>
            {CardTableFactory('Aktivní průkazy', activeCards)}
          </Route>
          <Route exact path={`${path}/blokovane`}>
            {CardTableFactory('Zablokované průkazy', blockedCards)}
          </Route>
        </Switch>
      </Grid>
    </Grid>
  );
}
