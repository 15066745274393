import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FormHelperText from '@material-ui/core/FormHelperText';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import colors from '../../../constants/colors';
import { NewCardValues, CardOrLicence } from './new-card-types';
import { usePickupPlaces } from './hooks/pickup-places-hook';
import { useFormErrors } from '../../../hooks/use-form-errors';
import {
  FormFieldSelect,
  FormFieldRadioGroup,
  FormTextField,
} from '../../../components/form';

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 700,
    color: colors.gray,
  },
  radios: {
    [theme.breakpoints.up('sm')]: {
      marginTop: '-11px',
    },
  },
  radioLabel: {
    fontWeight: 600,
  },
}));

type Props = {
  validate: () => Promise<void>;
  cardsData: {
    cardsAndLicences: CardOrLicence[];
    loading: boolean;
    noRights: boolean;
  };
  disabled?: boolean;
};

export function NewCardInfo({ validate, cardsData, disabled }: Props) {
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { getFieldError } = useFormErrors<NewCardValues>();
  const { places, loading: placesLoading } = usePickupPlaces();
  const { cardsAndLicences, loading: licencesLoading, noRights } = cardsData;

  if (licencesLoading || placesLoading) {
    return <LinearProgress />;
  }

  if (noRights) {
    return (
      <Typography color="error">
        Nemáte nárok na zakoupení žádného průkazu
      </Typography>
    );
  }

  return (
    <Grid container spacing={smallScreen ? 4 : 8}>
      <Grid item xs={12} sm={5} md={3} lg={2}>
        <InputLabel id="type" className={classes.label}>
          Typ průkazu*
        </InputLabel>
      </Grid>
      <Grid item xs={12} sm={7} md={9} lg={10} className={classes.radios}>
        <FormControl>
          <FormFieldRadioGroup
            name="cardId"
            onBlur={validate}
            disabled={disabled}
          >
            {cardsAndLicences.map((card) =>
              'licenceType' in card ? (
                <FormControlLabel
                  classes={{ label: classes.radioLabel }}
                  key={card.id}
                  value={card.id}
                  control={<Radio />}
                  label={`Průkaz ${card.licenceType} (${card.price} CZK) ${
                    card.licenceRightValidTo
                      ? `${card.licenceRightValidTo}`
                      : ''
                  }`}
                />
              ) : (
                <FormControlLabel
                  classes={{ label: classes.radioLabel }}
                  key={card.id}
                  value={card.id}
                  control={<Radio />}
                  label={`Průkaz ${card.cardType} (0 CZK) ${
                    card.cardRightValidTo ? `do ${card.cardRightValidTo}` : ''
                  }`}
                />
              )
            )}
          </FormFieldRadioGroup>
          <FormHelperText error>{getFieldError('cardId')}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={5} md={3} lg={2}>
        <InputLabel id="place" className={classes.label}>
          Místo vyzvednutí*
        </InputLabel>
      </Grid>
      <Grid item xs={12} sm={7} md={9} lg={10}>
        <FormControl>
          <FormFieldSelect
            native
            variant="outlined"
            name="pickupWorkplace"
            labelId="pickupWorkplace"
            onBlur={validate}
            disabled={disabled}
          >
            <option value="" disabled>
              Zvolte místo vyzvednutí
            </option>
            {places.map(({ label, id }) => (
              <option key={id} value={id}>
                {label}
              </option>
            ))}
          </FormFieldSelect>
          <FormHelperText error>
            {getFieldError('pickupWorkplace')}
          </FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={5} md={3} lg={2}>
        <InputLabel id="place" className={classes.label}>
          Email*
        </InputLabel>
      </Grid>
      <Grid item xs={12} sm={7} md={9} lg={10}>
        <FormControl>
          <FormTextField
            name="email"
            variant="outlined"
            placeholder="email@gmail.com"
            type="email"
            onBlur={validate}
            disabled={disabled}
          />
          <FormHelperText error>{getFieldError('email')}</FormHelperText>
        </FormControl>
      </Grid>
    </Grid>
  );
}
