export const fetchGdprApproval = async (approve: boolean): Promise<void> => {
  const endpoint = approve
    ? '/api/prukazy/auth/uk/forPerson/logged-in-person/esc-approval'
    : '/api/prukazy/auth/uk/forPerson/logged-in-person/esc-disapproval';

  const res = await fetch(endpoint, {
    method: 'PUT',
  });

  return res.ok ? Promise.resolve() : Promise.reject();
};
