import React, { useState } from 'react';

import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Grow from '@material-ui/core/Grow';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alingItems: 'center',
    padding: '0.2rem',
  },
  button: {
    padding: 5,
  },
  input: {
    margin: '0 0.75rem',
  },
}));

interface HeaderSearchProps {
  open: boolean;
}

export function HeaderSearch({ open }: HeaderSearchProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = useStyles();

  return (
    <Paper component="form" className={classes.root} variant="outlined">
      <Grow
        in={open || isExpanded}
        timeout={{ enter: 250, exit: 0 }}
        unmountOnExit
        mountOnEnter
      >
        <InputBase placeholder="Vyhledat" className={classes.input} />
      </Grow>
      <IconButton
        type={open ? 'submit' : 'button'}
        aria-label="search"
        className={classes.button}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {!isExpanded || open ? <SearchIcon /> : <CloseIcon />}
      </IconButton>
    </Paper>
  );
}
