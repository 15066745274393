import React, { useState, useCallback } from 'react';

import { HeaderSearch } from './header-search';
import { HeaderProfile } from './header-profile';

import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import colors from '../../constants/colors';
import { SidePanelNav } from '../side-panel/side-panel-nav';
import { logout } from './header-api';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '1rem',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '1rem',
    },
  },
  divider: {
    height: '2rem',
    width: 2,
    backgroundColor: colors.lightgray,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 2,
    },
  },
  logOutButton: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 'auto',
    },
  },
}));

export function HeaderMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenMenu = () => setIsOpen(true);
  const handleCloseMenu = () => setIsOpen(false);

  const handleLogout = useCallback(async () => {
    try {
      await logout();
    } catch (err) {
      // noop
    } finally {
      window.location.href = window.location.origin;
    }
  }, []);

  const component = (
    <div className={classes.container}>
      <HeaderSearch open={smallScreen} />
      <div className={classes.divider} />
      <HeaderProfile />
      <div className={classes.divider} />
      {smallScreen && <SidePanelNav />}
      <Button
        startIcon={<ExitToAppIcon color="primary" />}
        className={classes.logOutButton}
        onClick={handleLogout}
      >
        Odhlásit se
      </Button>
    </div>
  );

  return smallScreen ? (
    <>
      <Drawer anchor="right" open={isOpen} onClose={handleCloseMenu}>
        <div>
          <IconButton onClick={handleCloseMenu}>
            <ChevronRightIcon color="primary" />
          </IconButton>
        </div>
        {component}
      </Drawer>
      <IconButton onClick={handleOpenMenu}>
        <MenuIcon />
      </IconButton>
    </>
  ) : (
    component
  );
}
