import React from 'react';

import { NavItem } from './nav-item';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import { rootNavItems } from './nav-items';

const useStyles = makeStyles((theme) => ({
  nav: {
    width: '100%',
  },
  activeLink: {
    paddingLeft: `calc(${theme.spacing(2)}px - 4px)`,
    borderLeft: `4px solid ${theme.palette.primary.main}`,
    backgroundColor: '#eeeeee',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));

export function SidePanelNav() {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.nav}>
      {rootNavItems.map(({ label, linkTo, Icon, subItems = [] }) => (
        <NavItem
          key={label}
          label={label}
          Icon={Icon}
          linkTo={linkTo}
          activeClassName={classes.activeLink}
          subItems={subItems}
        />
      ))}
    </List>
  );
}
