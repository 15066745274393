import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import AccountBoxRoundedIcon from '@material-ui/icons/AccountBoxRounded';

import { CardIcon } from './icons/card-icon';
import { FilesIcon } from './icons/files-icon';
import { NavItemType } from './types';

const cardNavItems: NavItemType[] = [
  {
    label: 'Všechny průkazy',
    linkTo: '/portal/prukazy',
    Icon: FormatListBulletedIcon,
  },
  {
    label: 'Aktivní průkazy',
    linkTo: '/portal/prukazy/aktivni',
    Icon: CheckCircleRoundedIcon,
  },
  {
    label: 'Zablokované průkazy',
    linkTo: '/portal/prukazy/blokovane',
    Icon: LockRoundedIcon,
  },
];

export const rootNavItems: NavItemType[] = [
  {
    label: 'Moje průkazy',
    linkTo: '/portal/prukazy',
    Icon: CardIcon,
    subItems: cardNavItems,
  },
  {
    label: 'Moje soubory',
    linkTo: '/portal/soubory',
    Icon: FilesIcon,
  },
  {
    label: 'Profil',
    linkTo: '/portal/profil',
    Icon: AccountBoxRoundedIcon,
  },
];
