import React, { useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '@eas/common-web';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

import { Me } from '../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    gap: '1rem',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  userName: {
    fontWeight: 600,
    fontSize: '0.9rem',
  },
  avatarWrapper: {
    border: '2px solid lightgray',
    borderRadius: '50%',
  },
  avatar: {
    margin: 2,
  },
}));

export function HeaderProfile() {
  const classes = useStyles();
  const { user } = useContext<UserContext<Me>>(UserContext);

  return (
    <div className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar className={classes.avatar}>
          <PermIdentityIcon />
        </Avatar>
      </div>
      <span className={classes.userName}>{user?.username}</span>
    </div>
  );
}
