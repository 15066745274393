import React, { useContext } from 'react';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

import { Card } from '../../../../models';
import colors from '../../../../constants/colors';
import { CardDetailsItem } from './card-details-item';
import { DetailItems } from './detail-items';
import { DialogContext } from '../../../../components/dialog/dialog-context';
import { BlockCardDialogContent } from '../actions/block-card';
import { UnblockCardDialogContent } from '../actions/unblock-card';
import { CardPreview } from '../../../../components/card-preview/card-preview';

interface CardDetailsProps {
  data: Card;
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(55,81,255,0.02)',
  },
  unblockButton: {
    color: 'white',
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.green,
    },
  },
  infoItem: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  imageItem: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
}));

export function CardDetails({ data }: CardDetailsProps) {
  const classes = useStyles();
  const { open } = useContext(DialogContext);

  return (
    <Grid container direction="column" spacing={4} className={classes.root}>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item sm={12} md={8} className={classes.infoItem}>
            <Grid container spacing={3}>
              {DetailItems.map(({ label, render }) => (
                <CardDetailsItem
                  key={label}
                  label={label}
                  data={data}
                  render={render}
                />
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} md={4} className={classes.imageItem}>
            <CardPreview
              imageSrc={
                data.photo ? `data:image/png;base64,${data.photo}` : undefined
              }
              cardType={data.cardType}
              name={data.fullName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {data.typeOfHolder === 'Z' ? (
          <Button
            className={classes.unblockButton}
            variant="contained"
            startIcon={<LockOpenIcon style={{ fontSize: '1rem' }} />}
            onClick={() =>
              open({
                initialValues: {
                  cardId: data!.id,
                },
                Content: UnblockCardDialogContent,
                size: 'md',
              })
            }
          >
            Odblokovat průkaz
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            startIcon={<LockIcon style={{ fontSize: '1rem' }} />}
            onClick={() =>
              open({
                initialValues: {
                  cardId: data!.id,
                },
                Content: BlockCardDialogContent,
                size: 'md',
              })
            }
          >
            Zablokovat průkaz
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
