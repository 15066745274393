import { useState, useContext, useCallback } from 'react';
import { SnackbarVariant, SnackbarContext } from '@eas/common-web';

import { fetchGdprApproval } from './profile-api';
import { UserInfoContext } from '../../../components/user-info-context/user-info-context';
import { FormValues } from './profile-types';

export const useProfile = () => {
  const { userInfo, loading, reload } = useContext(UserInfoContext);
  const [processingApproval, setProcessingApproval] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  const handleSubmit = useCallback(
    async ({ gdpr }: FormValues) => {
      try {
        setProcessingApproval(true);
        await fetchGdprApproval(gdpr);
        showSnackbar('Nastavení úspěšně uloženo', SnackbarVariant.SUCCESS);
        reload();
      } catch (err) {
        showSnackbar('Odeslání selhalo', SnackbarVariant.ERROR);
      } finally {
        setProcessingApproval(false);
      }
    },
    [showSnackbar, reload]
  );

  return { userInfo, loading, handleSubmit, processingApproval };
};
