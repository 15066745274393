import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function FilesIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M21.3333 0H2.66667C1.2 0 0 1.2 0 2.66667V21.3333C0 22.8 1.2 24 2.66667 24H21.3333C22.8 24 24 22.8 24 21.3333V2.66667C24 1.2 22.8 0 21.3333 0ZM14.6667 18.6666H5.33334V16.0001H14.6667V18.6666ZM18.6666 13.3333H5.33334V10.6667H18.6666V13.3333ZM18.6666 8H5.33334V5.33334H18.6666V8Z" />
    </SvgIcon>
  );
}
